import React, { useContext } from "react";
import { DatasheetContext } from "./datasheet.context";
import { BASE_URL } from "../../../global";
import { Button, TextField, Typography } from "@mui/material";
import axiosWithToken from "../../../utils/components/axiosTokenConfig";

export default function Gauge() {
  const {
    state: { staticReadingRows, instrumentDetails, staticTables , datasheetDetails },
    dispatch
  } = useContext(DatasheetContext);

  const [gaugeData, setGaugeData] = React.useState({});

  const idsWhereIsGaugeTrue = staticTables?.filter(item => item?.defaultConfiguration?.isGauge)?.map(item => item.id);
  const prepareGaugeTable = () => {
    // calc. T
    let a = gaugeData?.size + gaugeData?.ht;
    let b = gaugeData?.size + gaugeData?.lt;
    let [g, k] = a > b ? [a, b] : [b, a];
    g = Number(g.toFixed(4));
    k = Number(k.toFixed(4));

    let t = Number((g - k).toFixed(3));

    let instrumentId = instrumentDetails?.isGauge == 5 ? 2 : 1;

    axiosWithToken
      .get(
        BASE_URL +
          `gauges?_where=(nominalSizeUpto,gte,${gaugeData?.size})~and(t,gte,${
            t * 1000
          })~and(instrumentId,eq,${instrumentId})`
      )
      .then((res) => {
        if (res.data?.length <= 0) return;
        // calculate values for go, no go, wear limit
        let data = res.data[0]; // for largest T
        data["Z"] = data.Z / 1000;
        data["H2"] = data.H2 / 1000;
        data["Y"] = data.Y / 1000;
        data["alpha"] = data.alpha / 1000;

        let specifiedSize = { go: "", nogo: "", wl: null };
        let go1, go2, nogo1, nogo2;

        // for plain gauge
        if (instrumentDetails?.isGauge != 5) {
          if (gaugeData.size <= 180) {
            // less than 180
            go1 = (k + data.Z + data.H2).toFixed(4);
            go2 = (k + data.Z - data.H2).toFixed(4);
            [go1, go2] = go1 < go2 ? [go1, go2] : [go2, go1];
            specifiedSize.go = go1 + " / " + go2;

            nogo1 = (g + data.H2).toFixed(4);
            nogo2 = (g - data.H2).toFixed(4);
            [nogo1, nogo2] = nogo1 < nogo2 ? [nogo1, nogo2] : [nogo2, nogo1];
            specifiedSize.nogo = nogo1 + " / " + nogo2;

            specifiedSize.wl = (k - data.Y).toFixed(4);
          } else {
            // more than 180
            go1 = (k + data.Z + data.H2).toFixed(4);
            go2 = (k + data.Z - data.H2).toFixed(4);
            [go1, go2] = go1 < go2 ? [go1, go2] : [go2, go1];
            specifiedSize.go = go1 + " / " + go2;

            nogo1 = (g - data.alpha + data.H2).toFixed(4);
            nogo2 = (g - data.alpha - data.H2).toFixed(4);
            [nogo1, nogo2] = nogo1 < nogo2 ? [nogo1, nogo2] : [nogo2, nogo1];
            specifiedSize.nogo = nogo1 + " / " + nogo2;

            specifiedSize.wl = (k - data.Y + data.alpha).toFixed(4);
          }
        } else {
          // for snap gauge
          if (gaugeData.size <= 180) {
            // less than 180
            go1 = (g - data.Z + data.H2).toFixed(4);
            go2 = (g - data.Z - data.H2).toFixed(4);
            [go1, go2] = go1 < go2 ? [go1, go2] : [go2, go1];
            specifiedSize.go = go1 + " / " + go2;

            nogo1 = (k + data.H2).toFixed(4);
            nogo2 = (k - data.H2).toFixed(4);
            [nogo1, nogo2] = nogo1 < nogo2 ? [nogo1, nogo2] : [nogo2, nogo1];
            specifiedSize.nogo = nogo1 + " / " + nogo2;

            specifiedSize.wl = (g + data.Y).toFixed(4);
          } else {
            // more  than 180
            go1 = (g - data.Z + data.H2).toFixed(4);
            go2 = (g - data.Z - data.H2).toFixed(4);
            [go1, go2] = go1 < go2 ? [go1, go2] : [go2, go1];
            specifiedSize.go = go1 + " / " + go2;

            nogo1 = (k + data.alpha + data.H2).toFixed(4);
            nogo2 = (k + data.alpha - data.H2).toFixed(4);
            [nogo1, nogo2] = nogo1 < nogo2 ? [nogo1, nogo2] : [nogo2, nogo1];
            specifiedSize.nogo = nogo1 + " / " + nogo2;

            specifiedSize.wl = (g + data.Y - data.alpha).toFixed(4);
          }
        }

        specifiedSize = [
          specifiedSize.go,
          specifiedSize.nogo,
          specifiedSize.wl,
        ];

        let IndexValue = 0;
        let _staticReadingRows = [...staticReadingRows];
        // Reorder rows so go, nogo, wear limit always start at index 0
        const gaugeTableRows = _staticReadingRows.filter(row => row[0] == idsWhereIsGaugeTrue[0]);
        const remainingRows = _staticReadingRows.filter(row => row[0] != idsWhereIsGaugeTrue[0]);
        // Check for _unit_ and _rh_ in any row
        gaugeTableRows.forEach((row) => {
          if (row[2]?.includes("_unit_") || row[2]?.includes("_rh_")) {
            IndexValue += 1;
          }
        });

        specifiedSize.forEach((val, index) => {
          if (gaugeTableRows[index + IndexValue]) {
            gaugeTableRows[index + IndexValue][4] = val;
          }
        });
  
        gaugeTableRows.forEach((row, index) => {
          if (index == IndexValue) {
            row[3] = instrumentDetails?.isGauge !== 5 ? k : g;
          } else if (index == 1 + IndexValue) {
            row[3] = instrumentDetails?.isGauge !== 5 ? g : k;
          } else if (index == 2 + IndexValue) {
            row[3] = "";
          }
        });
  
        const updatedRows = [...gaugeTableRows, ...remainingRows];
        dispatch({
          field: "staticReadingRows",
          value: updatedRows,
        });

        setGaugeData({
          ...gaugeData,
          isPrepared: true,
          g: g,
          k: k,
          t: t,
          h2: data.H2,
          alpha: data.alpha,
          z: data.Z,
          y: data.Y,
        });
      });
  };
  
  function PreapreRange() {
  let range = datasheetDetails?.ranges?.replaceAll("||", ", ")
  ?.replaceAll("|", " to ")
  ?.replaceAll("#", "")?.trim();
  let size, ht, lt;

  if (range.includes("/")) {
    // Case: e.g. if datasheet ranges is like this  34.2/34.8 includes "/" 
    let [val1, val2] = range.split('/').map(val => parseFloat(val));
    size = Math.min(val1, val2).toFixed(3);
    ht = Math.abs(val1 - val2).toFixed(3); 
    lt = 0;
  } else if (range.includes("±")) {
    // Case: e.g., if datasheet ranges is like this 42.00 ±1mm includes "±"
    let parts = range.split("±").map(part => part.trim());
    size = parseFloat(parts[0]).toFixed(3);
    ht = Math.abs(parseFloat(parts[1])).toFixed(3);
    lt = "-" + ht;
  } else if (range.includes("+")) {
    // Case: e.g., if datasheet ranges is like this 20+1 includes "+"
    let parts = range.split("+").map(part => part.trim());
    size = parseFloat(parts[0]).toFixed(3);
    ht = parseFloat(parts[1]).toFixed(3);
    lt = 0;
  } else if (range.includes("-")) {
    // Case: e.g., if datasheet ranges is like this 20-1 includes "-"
    let parts = range.split("-").map(part => part.trim());
    size = parseFloat(parts[0]).toFixed(3);
    ht = 0;
    lt = "-" + parseFloat(parts[1]).toFixed(3);
  } else {
    return;
  }

  // let max = (parseFloat(size) + parseFloat(ht)).toFixed(3);
  // let min = (parseFloat(size) + parseFloat(lt)).toFixed(3);
  // console.log({ max, min, size, ht, lt });
  setGaugeData({
    ...Gauge,
    size: parseFloat(size),
    ht: parseFloat(ht),
    lt: parseFloat(lt),
  });
}

React.useEffect(() => {
  PreapreRange();
}, [datasheetDetails.ranges]);


  const renderGaugeForm = () => {
    if (instrumentDetails?.isGauge) {
      return (
        <>
          <div>
            <Typography variant="h6" component="div">
              Prepare gauge data
            </Typography>
            <div>
              <TextField
                id="outlined-basic"
                label="Size"
                size="small"
                variant="outlined"
                type="number"
                value={gaugeData?.size ?? ""}
                onChange={(e) => {
                  const value = e.target.value.trim();
                  if (/^-?\d*\.?\d*$/.test(value)) {
                    setGaugeData({ ...gaugeData, size: value == "" ? "" : parseFloat(value) });
                  }
                }}
              />
              <TextField
                id="outlined-basic"
                label="Higher Tolerance"
                size="small"
                variant="outlined"
                type="number"
                value={gaugeData?.ht ?? ""}
                onChange={(e) => {
                  const value = e.target.value.trim();
                  if (/^-?\d*\.?\d*$/.test(value)) {
                    setGaugeData({ ...gaugeData, ht: value == "" ? "" : parseFloat(value) });
                  }
                }}
              />
              <TextField
                id="outlined-basic"
                label="Lower Tolerance"
                size="small"
                variant="outlined"
                type="number"
                value={gaugeData.lt ?? ""}
                onChange={(e) => {
                  const value = e.target.value.trim();
                  if (/^-?\d*\.?\d*$/.test(value)) {
                    setGaugeData({ ...gaugeData, lt: value == "" ? "" : parseFloat(value) });
                  }
                }}
              />
              <Button
                variant="contained"
                size="small"
                className="m-1"
                sx={{ m: 0 }}
                onClick={() => {
                  prepareGaugeTable();
                }}
              >
                Prepare
              </Button>
            </div>
            <div>
              {gaugeData.isPrepared != 0 && (
                <div className="m-2">
                  <Button className="m-1 p-1" variant="outlined">
                    G : {gaugeData.g ?? "-"}
                  </Button>
                  <Button className="m-1 p-1" variant="outlined">
                    K: {gaugeData.k ?? "-"}
                  </Button>
                  <Button className="m-1 p-1" variant="outlined">
                    T: {gaugeData.t?.toFixed(4) ?? "-"}
                  </Button>
                  <Button className="m-1 p-1" variant="outlined">
                    Y: {gaugeData.y ?? "-"}
                  </Button>
                  <Button className="m-1 p-1" variant="outlined">
                    Z: {gaugeData.z ?? "-"}
                  </Button>
                  <Button className="m-1 p-1" variant="outlined">
                    H/2: {gaugeData.h2 ?? "-"}
                  </Button>
                  <Button className="m-1 p-1" variant="outlined">
                    Aplha: {gaugeData.alpha ?? "-"}
                  </Button>
                </div>
              )}
            </div>
          </div>
          <hr />
        </>
      );
    }
  };
  return <div> {renderGaugeForm()}</div>;
}
