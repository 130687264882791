import unitConvertor from "../../../utils/components/unitConvertor";
import { resolveToken } from "../utils";

export const calculateUncertaintyDueToAirBuoyancyCorrectionFactorValue = (
  selectors,
  readings,
  rangeCol,
  standardRanges,
  uncertaintyUnit
) => {
  // Constants from Data Source (DS)
  const ap = Number(
    String(resolveToken("$datasheet.atmosphericPressure", selectors))?.split(
      "#"
    )?.[0] || "0"
  ); // Atmospheric Pressure from DS

  const Rh = Number(
    String(resolveToken("$datasheet.startHumidity", selectors))?.split(
      "#"
    )?.[0] || "0"
  ); // Humidity from DS
  const t = Number(
    String(resolveToken("$datasheet.startTemp", selectors))?.split("#")?.[0] ||
      "0"
  ); // Temperature from DS
  const DW = Number(
    String(resolveToken("$srfInstrument.ducDensity", selectors))?.split(
      "#"
    )?.[0] || "0"
  ); // Density of Weight from DUC details

  const NMTW = Number(readings[rangeCol]?.[0] || 0); // NMTW, replace with actual row range value from DS

  // collect standard and standard range data
  let standardRangeDetails = {};

  standardRanges.forEach((range) => {
    let standardId = range[0];
    let standardRangeId = range[1];

    let standardName = resolveToken(`$standard.standardName`, {
      ...selectors,
      standard: standardId,
    });
    standardRangeDetails[standardName] = {};
    standardRangeDetails[standardName]["uncertainty"] = resolveToken(
      `$standardRange.uncertainty`,
      { ...selectors, standardRange: standardRangeId }
    );
    standardRangeDetails[standardName]["uncertainty"] = Number(
      String(
        resolveToken(`$standardRange.uncertainty`, {
          ...selectors,
          standardRange: standardRangeId,
        })
      )?.split("#")?.[0] || "0"
    );

    standardRangeDetails[standardName]["materialDensity"] = Number(
      String(
        resolveToken(`$standardRange.materialDensity`, {
          ...selectors,
          standardRange: standardRangeId,
        })
      )?.split("#")?.[0] || "0"
    );
  });

  const DSW = standardRangeDetails["Weight Box"]?.materialDensity || 0; // Material density from Master EQP ranges == standerRanges

  const UDTB = standardRangeDetails["Barometer "]?.uncertainty || 0; // UDTB, replace with actual uncertainty from Barometer master EQP’s master range

  const UDTI = standardRangeDetails["Temp. Indicator"]?.uncertainty || 0; // UDTI, replace with actual uncertainty from Temp. Indicator master EQP’s master range

  const UDTRI = standardRangeDetails["Rh Indicator "]?.uncertainty || 0; // UDTRI, replace with actual uncertainty from Rh Indicator master EQP’s master range

  // Calculations
  let pa = (0.34848 * ap - 0.009 * Rh * Math.exp(0.061 * t)) / (273.15 + t); // Density of air during the measurement
  pa = unitConvertor(pa, "kg/m³", uncertaintyUnit); // Density converted to g/ml

  const pt = DW / 1000; // Density converted to g/ml
  const pr = DSW / 1000; // Material density converted to g/ml (1000 kg/m3 = 1g/ml = 1 g/cm3)

  const vt = NMTW / pt; // Volume of Test Weight
  const vr = NMTW / pr; // Volume of Reference Weight

  const uf = 0.0002 * pa; // Uncertainty of Formula used (for Approximation Formula)

  const up = UDTB / 2;
  const ut = UDTI / 2;
  const urh = UDTRI / 2;

  const upa = Math.sqrt(
    pa * (uf / pa) ** 2 + (up / ap) ** 2 + (ut / t) ** 2 + (urh / 10000) ** 2
  ); // Uncertainty in Density of Air
  const uvt = (0.14 / 2 / 7.95) * vt; // Uncertainty in Volume of Test Weight
  const uvr = (0.14 / 2 / 7.95) * vr; // Uncertainty in Volume of Reference Weight

  // reference density of air
  const po = 0.0012; // po, replace with actual value as needed

  // let UDTABC = Math.sqrt(
  //   pa * (uf / pa) ** 2 + (up / ap) ** 2 + (ut / t) ** 2 + (urh / 10000) ** 2
  // );

  // Final formula

  let res = Math.sqrt(((vt-vr)*(vt-vr))*((upa)*(upa))+((uvt)*(uvt))+((uvr)*uvr))*((pa-po)*(pa-po))

  console.log("custom factor", {
    pa,
    po,
    pt,
    vt,
    vr,
    upa,
    uvt,
    uvr,
    uf,
    up,
    ut,
    urh,
    t,
    ap,
    res
  });
  res = `${String(res)}#${uncertaintyUnit}`;

  return [res];
};

/*


Factor: Uncertainty due to air Buoyancy  correction 

Formula: √(Vt - Vr)^2 X (uρa)^2+(uvt2+ uvr2)X (Pa-Po)^2   

Step 1
ρa  Density of air during the measurement
Formula = [ (0.34848 X P )- 0.009 X Rh X exp(0.061 X t )] /(273.15+t )  kg/m3
Parameters:
P = Atmosphere Pressure from DS
Rh = Humidity form DS
t = temp. from DS                                                          
Density convert in g/ml ( 1000 kg/m3 = 1g/ml = 1g/cm3)

Step 2
ρt
Formula: Density of Weight (DUC)
Parameters:
Density of Weight = Density of Weight from DS                                                                                                             
Density convert in g/ml ( 1000 kg/m3 = 1g/ml=1 g/cm3)

Step 3
ρr
Formula: Density of Standard Weight     
Parameters:
Density of Standard Weight =   Material density from Master EQP range                                                                                                         
Density convert in g/ml ( 1000 kg/m3 = 1g/ml=1 g/cm3)

Step 4
Vt
Formula = Volume of test weight = Nominal mass of  test weight / ρt    
Parameters:
Nominal mass of  test weight (NMTW)= row range value from DS
Ρt = derive from ρt

Step 5
Vr
Formula   = Volume of reference weight = Nominal mass of  reference  weight / ρo
Parameters:
Nominal mass of  reference  weight = row range value from DS
Ρo = derive from ρr

Step 6
uf  = Uncertainty of Formula used (for Approximation Formula) 
Formula = 0.0002 * ρa
Param:
ρa = refer ρa

Step 7
up =  Uncertainty due to  Barometer
Formula: (Uncertainty due to  Barometer) / 2
Param:
Uncertainty due to  Barometer: uncertainty from Barometer master EQP’s master range

Step 8
ut =  Uncertainty due to  Temp. Indicator
Formula: (Uncertainty due to  Temp. Indicator) / 2
Param:
Uncertainty due to  Temp. Indicator: uncertainty from Temp. Indicator master EQP’s master range

Step 9
urh =  Uncertainty due to  Rh Indicator
Formula: (Uncertainty due to  Rh Indicator) / 2
Param:
Uncertainty due to  Rh Indicator: uncertainty from Rh Indicator master EQP’s master range

Step 10
uρa Uncertainty in Density of Air
Formula =  √ pa  [ (uf / pa )2 + (up/ P)2 + (ut / T )2 +(urh / 104 )2 ]
Parameters:
ρa  = refer ρa
uf  = refer uf 
P = Atmosphere Pressure from DS
Ut = refer ut
T =  temp. from DS
urh = rrefer urh

Step 11
uvt  Uncertainty in Volume of Test Weight        
Formula = ((0.14/2)/7.95)* Vt
Param:
Vt = erfer Vt

Step 12
uvr Uncertainty in Volume of Reference Weight
Formula = =((0.14/2)/7.95)* Vr
Param:
Vr = refer Vr


---
reference:
DS: datasheet


*/
